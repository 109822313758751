import 'core-js/stable';
import 'regenerator-runtime/runtime';
import Popper from 'popper.js';

class SafetyIndex {
  constructor() {
    this.popups = [];
    this.mapSelector = $('.safety-index-map');
    this.areaSelector = $('.safety-index-area');
    this.popupSelector = $('.safety-index-popup');
    this.popupClose = this.popupSelector.find('.close');
  }

  init() {
    if ($(window).width() > 769) {
      this.setPopupLocations();
      this.showPopupOnClick();
      // this.handleBlur();
    }
  }

  setPopupLocations() {
    if (this.areaSelector !== null && this.popupSelector !== null) {
      this.areaSelector.each((index, area) => {
        const popupTarget = $(area).data('popup-target');
        const popup = $(`.safety-index-popup[data-popup-id=${popupTarget}]`);
        let innerPopup = false;
        let popupOffset = 0;

        if (area.id === 'De_Pijp_en_Wijkerbroek') {
          innerPopup = true;
        }

        if (area.id === 'Centrum') {
          popupOffset = '35%r';
        }
        
        const popper = new Popper(area, popup, {
          placement: 'top',
          modifiers: {
            flip: {
              enabled: false,
            },
            offset: {
              offset: popupOffset,
            },
            inner: {
              enabled: innerPopup,
            },
          },
        });

        this.popups.push(popper);
      });
    }
  }

  showPopupOnClick() {
    if (this.areaSelector.length) {
      this.areaSelector.each((index, element) => {
        const area = $(element);
        const popupTarget = $(area).data('popup-target');
        const popup = $(`.safety-index-popup[data-popup-id=${popupTarget}]`);

        area.add(popup.find('.close')).on('click', () => {
          if (area.hasClass('toggled')) {
            this.removeActives();
            return;
          }

          this.removeActives();

          area.toggleClass('toggled');
          popup.toggleClass('visible');
        });
      });
    }
  }

  removeActives() {
    this.popupSelector.removeClass('visible');
    this.areaSelector.removeClass('toggled');
  }

  handleBlur() {
    let preventBlur = false;

    this.popupSelector.on('click', () => {
      preventBlur = true;
    });

    this.mapSelector.on('blur', () => {
      if (!preventBlur) {
        this.removeActives();
      } else {
        preventBlur = false;
      }
    });
  }
}

$(document).ready(() => new SafetyIndex().init());
